<template>
  <div>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
        @click="redirectTo('/empresas')"
      >
        <statistic-card-vertical
          class="hover-card"
          icon="BriefcaseIcon"
          :statistic="totalizadores[0].total_empresas"
          statistic-title="Empresas"
          color="primary"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="SettingsIcon"
          :statistic="totalizadores[0].total_os"
          statistic-title="OS"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
        @click="redirectTo('/seguradora')"
      >
        <statistic-card-vertical
          class="hover-card"
          color="info"
          icon="AwardIcon"
          :statistic="totalizadores[0].total_seguradoras"
          statistic-title="Seguradoras"
        />
      </b-col>
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="UsersIcon"
          :statistic="totalizadores[0].total_clientes"
          statistic-title="Clientes"
        />
      </b-col>
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="ArchiveIcon"
          :statistic="totalizadores[0].total_produtos"
          statistic-title="Produtos"
        />
      </b-col>
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
        @click="redirectTo('/revenda')"
      >
        <statistic-card-vertical
          class="hover-card"
          color="danger"
          icon="ShoppingBagIcon"
          :statistic="totalizadores[0].total_revendas"
          statistic-title="Revendas"
        />
      </b-col>
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
        @click="redirectTo('/marca')"
      >
        <statistic-card-vertical
          class="hover-card"
          hide-chart
          color="warning"
          icon="CommandIcon"
          :statistic="totalizadores[0].total_marcas"
          statistic-title="Marcas"
        />
      </b-col>
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
        @click="redirectTo('/linha')"
      >
        <statistic-card-vertical
          class="hover-card"
          hide-chart
          color="info"
          icon="AlignCenterIcon"
          :statistic="totalizadores[0].total_linhas"
          statistic-title="Linhas"
        />
      </b-col>
      <b-col
        class="cursor-pointer"
        xl="4"
        md="4"
        sm="6"
        @click="redirectTo('/tecnicos')"
      >
        <statistic-card-vertical
          class="hover-card"
          color="danger"
          icon="ToolIcon"
          :statistic="totalizadores[0].total_tecnicos"
          statistic-title="Tecnicos"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <cad-totalizadores-estado />
      </b-col>
      <b-col md="4">
        <cad-totalizadores-cidade />
      </b-col>
      <b-col md="4">
        <cad-totalizadores-seguradora />
      </b-col>
      <b-col md="4">
        <cad-totalizadores-revenda />
      </b-col>
      <b-col md="4">
        <cad-totalizadores-marca />
      </b-col>
      <b-col md="4">
        <cad-totalizadores-linha />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { kFormatter } from '@core/utils/filter'
import CadTotalizadoresSeguradora from './cards/TotalizadoresSeguradora.vue'
import CadTotalizadoresMarca from './cards/TotalizadoresMarca.vue'
import CadTotalizadoresRevenda from './cards/TotalizadoresRevenda.vue'
import CadTotalizadoresLinha from './cards/TotalizadoresLinha.vue'
import CadTotalizadoresEstado from './cards/TotalizadoresEstado.vue'
import CadTotalizadoresCidade from './cards/TotalizadoresCidade.vue'
import axios from '../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    CadTotalizadoresSeguradora,
    CadTotalizadoresMarca,
    CadTotalizadoresLinha,
    CadTotalizadoresCidade,
    CadTotalizadoresEstado,
    CadTotalizadoresRevenda,
  },
  data() {
    return {
      totalizadores: [],
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
    }
  },
  created() {
    this.getTotais()
    // Subscribers gained
    this.$http.get('/card/card-statistics/subscribers')
      .then(response => { this.subscribersGained = response.data })

    // Revenue Generated
    this.$http.get('/card/card-statistics/revenue')
      .then(response => { this.revenueGenerated = response.data })

    // Sales
    this.$http.get('/card/card-statistics/sales')
      .then(response => { this.quarterlySales = response.data })

    // Orders
    this.$http.get('/card/card-statistics/orders')
      .then(response => { this.ordersRecevied = response.data })

    // Site Traffic gained
    this.$http.get('/card/card-statistics/site-traffic')
      .then(response => { this.siteTraffic = response.data })

    // Active Users
    this.$http.get('/card/card-statistics/active-users')
      .then(response => { this.activeUsers = response.data })

    // Newsletter
    this.$http.get('/card/card-statistics/newsletter')
      .then(response => { this.newsletter = response.data })
  },
  methods: {
    redirectTo(rota) {
      this.$router.push(rota)
    },
    getTotais() {
      axios.get('api/empresas/totalizadores')
        .then(response => {
          this.totalizadores = response.data.dados
        })
    },
    kFormatter,
  },
}
</script>
<style>
  .hover-card:hover {
    background-color: #5998ab;
  }
</style>
