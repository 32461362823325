<template>
  <div>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          :statistic="moedaBR(indi.totalPago + indi.totalAguardando)"
          statistic-title="Valor Total"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="success"
          :statistic="moedaBR(indi.totalPago)"
          statistic-title="Valor Pago"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="TabletIcon"
          color="danger"
          :statistic="moedaBR(indi.totalMaoDeObra)"
          statistic-title="Mão de Obra"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MapIcon"
          color="info"
          :statistic="moedaBR(indi.totalVisita)"
          statistic-title="Visita"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="success"

          :statistic="moedaBR(indi.totalHoje)"
          statistic-title="Pago hoje"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="warning"
          :statistic="moedaBR(indi.totalAguardando)"
          statistic-title="Valor Aguardando Pagamento"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="SettingsIcon"
          color="warning"

          :statistic="moedaBR(indi.totalPecas)"
          statistic-title="Peças"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="primary"

          :statistic="moedaBR(indi.totalOutros)"
          statistic-title="Outros"
        />
      </b-col>
    </b-row>
    <b-card>
      <b-row class="mb-2">
        <b-col
          md="2"
        >
          <label>Status</label>
          <v-select
            v-model="statusFilter"
            :options="optionsStatus"
            multiple
          />
        </b-col>
        <b-col
          md="2"
        >
          <label>Serviços</label>
          <v-select
            v-model="servicosFilter"
            :options="optionsServicos"
            multiple
          />
        </b-col>
        <b-col
          md="2"
        >
          <label>Seguradora</label>
          <v-select
            v-model="seguradoraFilter"
            :options="optionsSeguradora"
            multiple
          />
        </b-col>
        <div class="col-md-2 mb-2">
          <label>Atendente</label>
          <v-select
            v-model="atendenteFilter"
            :options="optionsAtendenteFilter"
            multiple
          />
        </div>
        <b-col
          md="2"
        >
          <label>Tipo de Atendimento</label>
          <v-select
            v-model="tipoAtendimentoFilter"
            :options="optionsTipoAtendimento"
            multiple
          />
        </b-col>
        <b-col
          md="2"
        >
          <label>Dias em Aberto</label>
          <v-select
            v-model="QDAFilter"
            :options="optionsQDAFilter"
            multiple
          />
        </b-col>
        <b-col
          style="display: none"
          md="2"
        >
          <label>Marca</label>
          <v-select
            v-model="seguradoraFilter"
            :options="optionsMarca"
            multiple
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- modal editar nome do comprovante -->
        <b-modal
          id="editar-nome-arquivo"
          title="Atualizar nome do comprovante"
          hide-footer
        >
          <b-form @submit.prevent="atualizarAlias">
            <b-form-group
              label="Novo nome"
              label-for="alias"
            >
              <b-form-input
                id="alias"
                v-model="alias"
              />
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              style="margin-top: 5px;"
            >
              Salvar
            </b-button>
          </b-form>
        </b-modal>
        <!-- modal anexar arquivos-->
        <b-modal
          id="modal-comprovante"
          cancel-variant="outline-secondary"
          ok-title="Anexar"
          cancel-title="Cancelar"
          title="Anexar Comprovante"
          :ok-disabled="disableSendAnexo"
          @ok="sendAnexo"
        >
          Selecione o arquivo:
          <b-form-file
            v-model="file"
            placeholder=""
            drop-placeholder="Arquivo"
          />
          <b-form>
            <b-form-group class="mt-1">
              <label>Data de Pagamento</label>
              <b-form-input
                id="abertura-inicio"
                v-model="dataPagamento"
                locale="pt"
                type="date"
              />
              <label for="observacao">Observação:</label>
              <b-form-input
                id="observacao"
                v-model="observacaoComprovante"
                type="text"
                placeholder="Ex: Comprovante"
              />
              <label
                for="observacao"
                class="mt-1"
              >
                Nome do para o comprovante (opcional):
              </label>
              <b-form-input
                id="observacao"
                v-model="aliasComprovante"
                type="text"
                placeholder="Nome para o comprovante"
              />
              <div v-if="!orcamentoAvulso">
                <label>Selecione os serviços: </label>
                <v-select
                  v-model="selectedServicosOrcamentos"
                  :options="servicosOrcamentos"
                  multiple
                  :close-on-select="false"
                />
              </div>
            </b-form-group>
          </b-form>
        </b-modal>
        <!-- modal visualizar arquivo-->
        <b-modal
          id="preview"
          ref="preview"
          hide-footer
          size="xl"
          title="Visualizar Comprovante"
        >
          <b-row>
            <!-- Renderizar a lista de imagens disponíveis -->
            <b-col
              v-for="data in comprovantes"
              :key="data.id"
              md="2"
            >
              <div>
                <b-card
                  class="hoverable"
                  @click="visualizarArquivo(data.nome_arquivo)"
                >
                  <b-avatar
                    :src="endpoint + '/storage/' + data.nome_arquivo"
                    size="100%"
                    rounded
                  />
                  <b-card-title>
                    {{ data.alias }}
                  </b-card-title>
                </b-card>
                <feather-icon
                  v-b-tooltip.hover.left="'Editar Nome'"
                  class="color-icon ml-1 cursor-pointer"
                  size="16"
                  icon="EditIcon"
                  @click="abrirModal(data)"
                />
                <feather-icon
                  v-b-tooltip.hover.right="'Excluir Comprovante'"
                  class="color-icon ml-1 cursor-pointer"
                  size="16"
                  icon="TrashIcon"
                  @click="excluirComprovante(data)"
                />
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="visualizar-arquivo"
          size="xl"
          title="Arquivo"
        >
          <iframe
            id="myFrame"
            :src="srcArquivo"
            style="width: 100%; height: 80vh"
          />
        </b-modal>
        <!--modal visualizar dados bancario-->
        <b-modal
          ref="modalVisulizarDadosBancarios"
          title="Dados Bancários"
          ok-title="Ok"
          size="xl"
          cancel-title="Fechar"
          cancel-variant="danger"
          @hidden="showDadosBancarios = false"
        >
          <div v-if="showDadosBancarios">
            <b-col>
              <label><strong>NOME</strong></label>
              <b-form-input
                :value="viewDadosBancarios[0].nome ? viewDadosBancarios[0].nome : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label for=""><strong>NOME FAVORECIDO</strong></label>
              <b-form-input
                :value="viewDadosBancarios[0].nome_favorecido ? viewDadosBancarios[0].nome_favorecido : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label for=""><strong>CHAVE PIX</strong></label>
              <b-form-input
                :value="viewDadosBancarios[0].chave_pix ? viewDadosBancarios[0].chave_pix : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label for=""><strong>BANCO</strong></label>
              <b-form-input
                :value=" viewDadosBancarios[0].banco ? viewDadosBancarios[0].banco : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label for=""><strong>AGENCIA</strong></label>
              <b-form-input
                :value="viewDadosBancarios[0].agencia ? viewDadosBancarios[0].agencia : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label for=""><strong>CONTA</strong></label>
              <b-form-input
                :value="viewDadosBancarios[0].conta ? viewDadosBancarios[0].conta : ''"
                disabled
              />
            </b-col>
          </div>
          <template #modal-footer="{}">
            <b-button
              v-show="viewDadosBancarios.status === 'APROVADO - AGUARDANDO PAGAMENTO'"
              class="mr-1"
              size="md"
              variant="outline-warning"
              @click="openModalRevisarOrcamento"
            >
              Revisão
            </b-button>
          </template>
        </b-modal>
        <!--modal visualizar dados bancario-->
        <div class="m-2">

          <!-- Table Top -->
          <b-row
            style="gap: 5px;"
          >
            <!-- Search -->
            <b-col>
              <b-form-select
                v-model="perPage"
                style="width: 70px; height: 32px; margin-top: 12px;"
                label="text"
                :options="[10, 25, 50, 100]"
                size="sm"
              />
            </b-col>
            <b-col>
              <b-form-input
                v-model="searchQuery"
                style="width: 850px; height: 32px; margin-top: 12px;"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                :options="[10, 15, 20]"
                type="search"
                size="sm"
                width="100%"
              />
            </b-col>
            <b-col>
              <b-button
                v-show="listFiltered.length > 0"
                style="width: 150px; height: 45px;"
                variant="outline-warning"
                @click="exportFile"
              >
                Exportar excel
              </b-button>
            </b-col>
          </b-row>

        </div>
        <b-col cols="12">
          <b-overlay
            variant="white"
            :show="loading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="listFiltered"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template #cell(status)="data">
                <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
                  {{ data.item.status }}
                </b-badge>
              </template>
              <template #cell(valor_total_orcamento)="data">
                {{ data.item.valor_total_orcamento ? valorBr(parseFloat(data.item.valor_total_orcamento),true) : '' }}
              </template>
              <template #cell(valor_total_aprovado)="data">
                {{ data.item.valor_total_aprovado ? valorBr(parseFloat(data.item.valor_total_aprovado),true) : '' }}
              </template>
              <template #cell(valor_total_seguradora)="data">
                {{ data.item.valor_total_seguradora ? valorBr(parseFloat(data.item.valor_total_seguradora),true) : '' }}
              </template>
              <template #cell(data_retorno_orcamento)="data">
                {{ data.item.data_retorno_orcamento ? formatTimezone(data.item.data_retorno_orcamento) : '' }}
              </template>
              <template #cell(data_pagamento)="data">
                {{ data.item.data_pagamento ? formatTimezone(data.item.data_pagamento) : '' }}
              </template>
              <template #cell(acoes)="data">
                <b-row style="width: 200px">
                  <b-col>
                    <feather-icon
                      v-show="data.item.status !== 'APROVADO - AGUARDANDO PAGAMENTO'"
                      v-b-tooltip.hover.left="'Visualizar comprovante'"
                      class="color-icon ml-1 cursor-pointer"
                      size="16"
                      icon="EyeIcon"
                      @click="visualizarComprovante(data.item)"
                    />

                    <feather-icon
                      v-show="data.item.status !== 'APROVADO - AGUARDANDO PAGAMENTO'"
                      v-b-tooltip.hover.top="'Compartilhar comprovante'"
                      class="plus-icon ml-1 cursor-pointer"
                      size="16"
                      icon="Share2Icon"
                      @click="confirmarPagamento(data.item)"
                    />

                    <feather-icon
                      v-show="data.item.status === 'APROVADO - AGUARDANDO PAGAMENTO' || data.item.status === 'APROVADO - PAGO PARCIAL'"
                      v-b-tooltip.hover.top="'Anexar comprovante'"
                      class="color-icon ml-1 cursor-pointer"
                      size="16"
                      icon="FileTextIcon"
                      cursor=""
                      @click="anexarComprovante(data.item)"
                    />

                    <feather-icon
                      v-b-tooltip.hover.top="'Dados Bancários'"
                      class="color-icon ml-1 cursor-pointer"
                      size="16"
                      icon="DollarSignIcon"
                      @click="visualizarDadosBancarios(data.item)"
                    />

                    <feather-icon
                      v-b-tooltip.hover.top="'Detalhes'"
                      class="color-icon ml-1 cursor-pointer"
                      size="16"
                      icon="ActivityIcon"
                      @click="openModalOrcamento(data.item)"
                    />

                    <feather-icon
                      v-b-tooltip.hover.top="'Histórico'"
                      class="color-icon ml-1 cursor-pointer"
                      size="16"
                      icon="AlignCenterIcon"
                      @click="openModalHistorico(data.item)"
                    />
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-overlay>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
      <b-modal
        id="modal-detalhe-orcamento"
        ref="showModalOrcamento"
        size="xl"
        :title="`Orcamento ${infoOrcamento.id}`"
      >
        <b-tabs>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Orcamento</span>
            </template>

            <b-row class="mb-1">
              <b-col md="2">
                <label><strong>Sinistro</strong></label>
                <b-form-input
                  :value="infoOrcamento.sinistro"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Data Abertura</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_abertura ? formatTimezone(infoOrcamento.data_abertura, true) : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Status</strong></label>
                <b-form-input
                  :value="infoOrcamento.status"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Vl Total Orcamento</strong></label>
                <b-form-input
                  :value="infoOrcamento.valor_total_orcamento ? valorBr(infoOrcamento.valor_total_orcamento, true) : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Vl Aprovado no Sistema</strong></label>
                <b-form-input
                  :value="infoOrcamento.valor_total_aprovado ? valorBr(infoOrcamento.valor_total_aprovado, true) : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Tipo Orçamento</strong></label>
                <b-form-input
                  :value="infoOrcamento.tipo_orcamento"
                  disabled
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <label><strong>Data Criação</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_criacao ? formatTimezone(infoOrcamento.data_criacao, true) : ''"
                  disabled
                />
              </b-col>
              <b-col>
                <label><strong>Data Auditação</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_auditacao ? formatTimezone(infoOrcamento.data_auditacao, true) : ''"
                  disabled
                />
              </b-col>
              <b-col>
                <label><strong>Data Envio Orçamento</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_envio_orcamento ? formatTimezone(infoOrcamento.data_envio_orcamento, true) : ''"
                  disabled
                />
              </b-col>
              <b-col>
                <label><strong>Status Retorno</strong></label>
                <b-form-input
                  :value="infoOrcamento.status_retorno"
                  disabled
                />
              </b-col>
              <b-col>
                <label><strong>Valor Total Seguradora</strong></label>
                <b-form-input
                  :value="infoOrcamento.valor_total_seguradora"
                  disabled
                />
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <label><strong>Retorno Orçamento</strong></label>
                    <b-form-input
                      :value="infoOrcamento.data_retorno_orcamento ? formatTimezone(infoOrcamento.data_retorno_orcamento, true) : ''"
                      disabled
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr>
            <b-row
              v-for="(dados,index) in infoOrcamento.servicos"
              :key="index"
              class="mb-1"
            >
              <b-col md="2">
                <label for=""><strong>Tipo Servico</strong></label>
                <b-form-input
                  :value="dados.tipo_servico"
                  disabled
                />
              </b-col>
              <b-col md="3">
                <label for=""><strong>Descrição</strong></label>
                <b-form-input
                  :value="dados.descricao"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Observação</strong></label>
                <b-row>
                  <b-col>
                    <b-form-input
                      :value="dados.observacao"
                      disabled
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="1">
                <label for=""><strong>Quant.</strong></label>
                <b-form-input
                  :value="dados.quantidade"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Solicitado</strong></label>
                <div class="d-flex">
                  <b-form-input
                    :value="dados.valor_solicitado"
                    disabled
                  />
                </div>
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Aprovado</strong></label>
                <b-row>
                  <b-col>
                    <b-form-input
                      :value="dados.valor_aprovado"
                      disabled
                    />
                  </b-col>
                  <feather-icon
                    v-show="dados.status === 'PAGO'"
                    v-b-tooltip.hover.top="'PAGO'"
                    class="plus-icon iconeThumbsUp mt-1"
                    size="16"
                    icon="CheckCircleIcon"
                  />
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col md="">
                <label for=""><strong>Atendente</strong></label>
                <b-form-input
                  :value="infoOrcamento.atendente.name"
                  disabled
                />
              </b-col>
            </b-row>

          </b-tab>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Prestador de Serviço</span>
            </template>

            <b-row class="mb-1">
              <b-col md="2">
                <label><strong>NOME</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.nome : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CPF_CNPJ</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.cpf_cnpj : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CHAVE PIX</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.chave_pix : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>BANCO</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.banco : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>AGENCIA</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.agencia : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CONTA</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.conta : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>TELEFONE</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.telefone : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>EMAIL</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.email : ''"
                  disabled
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-primary"
            @click="toViewOs(infoOrcamento.ordem_servico.id)"
          >
            Ir Para OS
            <feather-icon
              class="color-icon"
              size="16"
              icon="ExternalLinkIcon"
            />
          </b-button>
        </template>
      </b-modal>
      <b-modal
        ref="modalRevisarOrcamento"
        title="Revisar Orçamento"
        ok-title="Revisar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        :ok-disabled="motivoRevisaoOrcamento === ''"
        @ok="revisarOrcamento"
      >
        <b-form-group>
          <b-form-textarea v-model="motivoRevisaoOrcamento" />
        </b-form-group>
      </b-modal>
      <b-modal
        ref="viewHistorico"
        title="Histórico de Pagamento"
        ok-only
      >
        <app-timeline>
          <app-timeline-item
            v-for="item in historico"
            :key="item.id"
          >
            <div>
              <h6>
                #{{ item.sinistro }}
              </h6>

              <b-list-group
                flush
                class="mt-1"
              >
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Atendente: </strong>{{ item.atendente }}</span>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Tipo Serviço: </strong>{{ item.tipo_servico }}</span>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Produto: </strong>{{ item.nome_produto }}</span>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Data Solicitação: </strong>{{ item.data_solicitacao ? formatTimezone(item.data_solicitacao) : '' }}</span>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Data Pagamento: </strong>{{ item.data_pagamento ? formatTimezone(item.data_pagamento) : '' }}</span>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Valor pago: </strong>{{ item.valor_pago ? valorBr(parseFloat(item.valor_pago), true) : '' }}</span>
                </b-list-group-item>
                <br>
              </b-list-group>
            </div>
          </app-timeline-item>
        </app-timeline>
      </b-modal>
    </b-card>
  </div>

</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BModal, BBadge, VBTooltip, BForm, BFormInput, BFormFile, BCard, BFormGroup, BFormSelect, BButton, BFormTextarea, BAvatar, BListGroup, BListGroupItem, BOverlay,
  BTabs, BTab,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
import axios from '../../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BModal,
    BBadge,
    BForm,
    BFormInput,
    BFormFile,
    BCard,
    BFormGroup,
    vSelect,
    BFormSelect,
    BButton,
    BFormTextarea,
    BAvatar,
    StatisticCardHorizontal,
    AppTimeline,
    AppTimelineItem,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    updategrid: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      endpoint: process.env.VUE_APP_ROOT_API,
      nome_arquivo: null,
      perPage: 100,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'acoes', label: 'Ações', class: 'd-flex' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'data_abertura',
          label: 'Abertura',
          class: 'text-center',
          formatter(value) {
            const a = value.split(' ')
            const b = a[0].split('-')
            const dataBr = `${b[2]}/${b[1]}/${b[0]}`
            return dataBr
          },
        },
        { key: 'dias_vencidos',
          label: 'QDA',
          class: 'text-center',
          sortable: true,
          formatter(value, key, item) {
            const dataAtual = new Date()
            const dataAbertura = new Date(item.data_abertura)
            const diferencaEmDias = Math.floor((dataAtual - dataAbertura) / (1000 * 60 * 60 * 24))
            return diferencaEmDias < 0 ? 'Em andamento' : `${diferencaEmDias}`
          },
        },
        { key: 'tipo_atendimento', label: 'Tipo de Atendimento', sortable: true },
        { key: 'sinistro', label: 'Sinistro', sortable: true },
        { key: 'seguradora', label: 'Seguradora', sortable: true },
        { key: 'valor_total_orcamento', label: 'Valor Solicitado', sortable: true },
        { key: 'valor_total_aprovado', label: 'Valor Aprovado', sortable: true },
        { key: 'valor_total_seguradora', label: 'Valor Aprovado Seguradora', sortable: true },
        { key: 'data_retorno_orcamento', label: 'Data Aprovação', sortable: true },
        { key: 'data_pagamento', label: 'Data Pagamento', sortable: true },
        { key: 'atendente', label: 'Atendente', sortable: true },
        { key: 'revenda', label: 'Revenda', sortable: true },
        { key: 'produto', label: 'Produto', sortable: true },
        { key: 'marca', label: 'Marca', sortable: true },
        { key: 'linha', label: 'Linha', sortable: true },
      ],
      alias: null,
      aliasId: null,
      orcamento_id: null,
      file: null,
      observacaoComprovante: '',
      dataPagamento: '',
      aliasComprovante: '',
      viewDadosBancarios: [],
      showDadosBancarios: false,
      statusFilter: [],
      atendenteFilter: [],
      seguradoraFilter: [],
      linhaFilter: [],
      marcaFilter: [],
      revendaFilter: [],
      tipoAtendimentoFilter: [],
      QDAFilter: [],
      optionsQDAFilter: ['Mais de 5 dias', 'Mais de 10 dias', 'Mais de 15 dias', 'Mais de 30 dias'],
      servicosFilter: [],
      visualizarDadosModal: false,
      infoOrcamento: {},
      motivoRevisaoOrcamento: '',
      servicosOrcamentos: [],
      selectedServicosOrcamentos: [],
      comprovantes: [],
      srcArquivo: null,
      orcamentoAvulso: false,
      indi: {},
      historico: [],
      load: false,
    }
  },
  computed: {
    ...mapState({
      items: state => state.orcamento.items,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    optionsStatus() {
      const { items } = this
      const status = []
      items.map(item => {
        status.push(item.status)
      })
      const uniqueStatus = [...new Set(status)]
      return uniqueStatus
    },
    optionsSeguradora() {
      const { items } = this
      const seguradora = []
      items.map(item => {
        item.seguradora ? seguradora.push(item.seguradora) : null
      })
      const uniqueStatus = [...new Set(seguradora)]
      return uniqueStatus
    },
    optionsRevenda() {
      const { items } = this
      const revenda = []
      items.map(item => {
        revenda.push(item.ordem_servico.produto.revenda.nome)
      })
      const uniqueStatus = [...new Set(revenda)]
      return uniqueStatus
    },
    optionsMarca() {
      const { items } = this
      const marca = []
      items.map(item => {
        marca.push(item.marca)
      })
      const uniqueStatus = [...new Set(marca)]
      return uniqueStatus
    },
    optionsLinha() {
      const { items } = this
      const linha = []
      items.map(item => {
        linha.push(item.linha)
      })
      const uniqueStatus = [...new Set(linha)]
      return uniqueStatus
    },
    optionsAtendenteFilter() {
      const { items } = this
      const atendente = []
      items.map(item => {
        atendente.push(item.atendente)
      })
      const uniqueStatus = [...new Set(atendente)]
      return uniqueStatus
    },
    optionsTipoAtendimento() {
      const { items } = this
      const tipoAtendimento = []
      items.map(item => {
        tipoAtendimento.push(item.tipo_atendimento)
      })
      const uniquetipoAtendimento = [...new Set(tipoAtendimento)]
      return uniquetipoAtendimento
    },
    optionsServicos() {
      const { items } = this
      const tipoServicos = items.reduce((acc, item) => {
        if (item.servicos && item.servicos.length > 0) {
          item.servicos.forEach(servico => {
            if (!acc.includes(servico.tipo_servico)) {
              acc.push(servico.tipo_servico)
            }
          })
        }
        return acc
      }, [])
      return tipoServicos
    },
    listFiltered() {
      const { items, statusFilter: sF, tipoAtendimentoFilter: tF, atendenteFilter: aF, servicosFilter: tsF, seguradoraFilter: sgF, searchQuery } = this
      let list = items

      const filterQueryList = {
        sF: {
          filer: sF,
          active: false,
        },
        tF: {
          filer: tF,
          active: false,
        },
        aF: {
          filer: aF,
          active: false,
        },
        tsF: {
          filer: tsF,
          active: false,
        },
        sgF: {
          filer: sgF,
          active: false,
        },
      }

      // Filtro por status
      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status))
      }

      // Filtro por status
      if (tF.length > 0) {
        filterQueryList.tF.active = true
      }

      // Filtro por Atendente
      if (aF.length > 0) {
        filterQueryList.aF.active = true
      }
      // Filtro por Tipo de Serviço
      if (tsF.length > 0) {
        filterQueryList.tsF.active = true
      }

      // Filtro por Seguradora
      if (sgF.length > 0) {
        filterQueryList.sgF.active = true
      }
      list = list.filter(item => {
        let filterCheck = null
        Object.keys(filterQueryList).forEach(key => {
          if (filterQueryList[key].active) {
            filterCheck = false
            switch (key) {
              case 'tF':
                filterCheck = filterCheck || tF.includes(item.tipo_atendimento)
                break
              case 'aF':
                filterCheck = filterCheck || aF.includes(item.atendente.name)
                break
              case 'tsF': {
                const tiposServico = item.servicos.map(servico => servico.tipo_servico)
                filterCheck = filterCheck || tsF.every(filtro => tiposServico.includes(filtro))
                break
              }
              case 'sgF':
                filterCheck = filterCheck || sgF.includes(item.seguradora)
                break

              default:
                break
            }
          }
        })
        if (this.avaibleSearchQuery) {
          filterCheck = filterCheck || item.sinistro.includes(searchQuery)
        }
        if (filterCheck === null) {
          return true
        }
        return filterCheck
      })

      this.totalRows = list.length
      this.indicadores(list)

      this.$store.commit('SET_FILTERED_ITEMS', list)
      return list
    },
    avaibleSearchQuery() {
      const { searchQuery } = this

      if (searchQuery.length >= 3) {
        return true
      }

      return false
    },
    disableSendAnexo() {
      if (!this.file || (!this.orcamentoAvulso && this.selectedServicosOrcamentos.length === 0)) {
        return true
      }
      return false
    },
  },
  methods: {
    abrirModal(data) {
      this.alias = data.alias
      this.aliasId = data.id
      this.$bvModal.show('editar-nome-arquivo')
    },
    excluirComprovante(data) {
      const arquivoId = data.id
      this.$swal({
        title: 'Tem certeza?',
        text: 'Ao excluir o comprovante será cancelado o pagamento dos serviços vinculados!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$bvModal.hide('preview')
          axios.get(`api/comprovante/delete/${arquivoId}`)
            .then(() => {
              this.updategrid()
              this.$swal({
                icon: 'success',
                title: 'Excluído!',
                text: 'Comprovante excluído com sucesso.',
                timer: 1500,
                showConfirmButton: false,
              })
            })
        }
      })
    },
    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
    indicadores(dados) {
      const dataAtual = new Date()
      let dia = dataAtual.getDate()
      let mes = dataAtual.getMonth() + 1
      const ano = dataAtual.getFullYear()

      if (mes < 10) {
        mes = `0${mes}`
      }
      if (dia < 10) {
        dia = `0${dia}`
      }
      const hoje = `${ano}-${mes}-${dia}`
      let totalVisita = 0
      let totalMaoDeObra = 0
      let totalPecas = 0
      let totalOutros = 0
      let totalPago = 0
      let totalAguardando = 0
      let totalHoje = 0
      dados.map(item => {
        if (item.status === 'APROVADO - AGUARDANDO PAGAMENTO' || item.status === 'APROVADO - PAGO' || item.status === 'APROVADO - PAGO PARCIAL') {
          item.servicos.map(s => {
            if (hoje === s.data_pagamento) {
              totalHoje += parseFloat(s.valor_aprovado)
            }
            if (s.tipo_servico === 'VISITA') {
              if (s.status === 'PAGO') {
                totalPago += parseFloat(s.valor_aprovado)
              } else {
                totalAguardando += parseFloat(s.valor_aprovado)
              }
              totalVisita += parseFloat(s.valor_aprovado)
            } else if (s.tipo_servico === 'PEÇAS') {
              if (s.status === 'PAGO') {
                totalPago += parseFloat(s.valor_aprovado)
              } else {
                totalAguardando += parseFloat(s.valor_aprovado)
              }
              totalPecas += parseFloat(s.valor_aprovado)
            } else if (s.tipo_servico === 'MÃO DE OBRA') {
              if (s.status === 'PAGO') {
                totalPago += parseFloat(s.valor_aprovado)
              } else {
                totalAguardando += parseFloat(s.valor_aprovado)
              }
              totalMaoDeObra += parseFloat(s.valor_aprovado)
            } else {
              if (s.status === 'PAGO') {
                totalPago += parseFloat(s.valor_aprovado)
              } else {
                totalAguardando += parseFloat(s.valor_aprovado)
              }
              totalOutros += parseFloat(s.valor_aprovado)
            }
          })
        }
      })
      const Indicadores = {
        totalVisita,
        totalMaoDeObra,
        totalPecas,
        totalOutros,
        totalPago,
        totalAguardando,
        totalHoje,
      }
      this.indi = Indicadores
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.listFiltered

      worksheet.columns = [
        { header: 'ID', key: 'id' },
        { header: 'NOME', key: 'nome' },
        { header: 'STATUS', key: 'status' },
        { header: 'ABERTURA', key: 'ordem_servico.data_abertura' },
        { header: 'DIAS VENCIDOS', key: 'dias_vencidos' },
        { header: 'TIPO ATENDIMENTO', key: 'ordem_servico.tipo_atendimento' },
        { header: 'SINISTRO', key: 'ordem_servico.sinistro' },
        { header: 'SEGURADORA', key: 'ordem_servico.produto.seguradora.nome' },
        { header: 'D. ORÇAMENTO RETORNO', key: 'data_retorno_orcamento' },
        { header: 'D. PAGAMENTO', key: 'data_pagamento' },
        { header: 'Atendente', key: 'atendente.name' },
        { header: 'REVENDA', key: 'ordem_servico.produto.revenda.nome' },
        { header: 'PRODUTO', key: 'ordem_servico.produto.nome' },
        { header: 'MARCA', key: 'ordem_servico.produto.marca.nome' },
        { header: 'LINHA', key: 'ordem_servico.produto.linha.nome' },
        { header: 'V. TOTAL ORÇAMENTO', key: 'valor_total_orcamento' },
        { header: 'V. TOTAL APROVADO', key: 'valor_total_aprovado' },
        { header: 'V. TOTAL SEGURADORA', key: 'valor_total_seguradora' },
      ]

      const wk1Columns = [
        'valor_total_orcamento',
        'valor_total_aprovado',
        'valor_total_seguradora',
      ]

      const totais = {
        wk1: {},
      }

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.nome,
          item.status,
          item.data_abertura ? this.formatTimezone(item.data_abertura) : null,
          item.dias_vencidos,
          item.tipo_atendimento,
          item.sinistro,
          item.seguradora,
          item.data_retorno_orcamento ? this.formatTimezone(item.data_retorno_orcamento) : null,
          item.data_pagamento ? this.formatTimezone(item.data_pagamento) : null,
          item.atendente,
          item.revenda,
          item.produto,
          item.marca,
          item.linha,
          item.valor_total_orcamento ? this.valorBr(parseFloat(item.valor_total_orcamento)) : null,
          item.valor_total_aprovado ? this.valorBr(parseFloat(item.valor_total_aprovado)) : null,
          item.valor_total_seguradora ? this.valorBr(parseFloat(item.valor_total_seguradora)) : null,
        ])
        wk1Columns.map(w => {
          if (item[w] !== null) {
            totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
          }
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      const totalRowWk1 = worksheet.addRow({
        valor_total_orcamento: totais.wk1.valor_total_orcamento,
        valor_total_aprovado: totais.wk1.valor_total_aprovado,
        valor_total_seguradora: totais.wk1.valor_total_seguradora,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório financeiro', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    atualizarAlias() {
      axios.put(`api/comprovante/atualizar/alias/${this.aliasId}`, { alias: this.alias })
        .then(() => {
          this.comprovantes.map((c, index) => {
            if (c.id === this.aliasId) {
              this.comprovantes[index].alias = this.alias
            }
          })
          this.$bvModal.hide('editar-nome-arquivo')
          this.$swal({
            title: 'Nome do arquivo atualizado!',
            icon: 'success',
            timer: 1800,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.load = false
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: `Ocorreu um erro: ${error.response.data.mensagem}`,
          })
        })
    },
    anexarComprovante(item) {
      this.servicosOrcamentos = []
      this.orcamento_id = item.orcamento_id
      if (item.servicos.length > 0) {
        item.servicos.map(servico => {
          if (servico.status !== 'PAGO') {
            this.servicosOrcamentos.push({
              id: servico.id,
              label: servico.tipo_servico,
            })
          }
        })
      }
      this.$bvModal.show('modal-comprovante')
    },
    async sendAnexo() {
      this.load = true
      const formData = new FormData()
      formData.append('arquivo', this.file)
      formData.append('orcamento_id', this.orcamento_id)
      formData.append('data_pagamento', this.dataPagamento)
      if (this.aliasComprovante.length) {
        formData.append('alias', this.aliasComprovante)
      }
      if (this.selectedServicosOrcamentos.length > 0) {
        formData.append('servicos_id', JSON.stringify(this.selectedServicosOrcamentos.map(item => item.id)))
      }
      await axios.post('api/comprovante/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.updategrid()
          this.clearFormAnexarComprovante()
          this.load = false
          this.$swal({
            title: 'Sucesso!',
            text: 'Comprovante anexado',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.updategrid()
          this.clearFormAnexarComprovante()
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    async visualizarComprovante(item) {
      await this.getComprovantes(item.orcamento_id)
      this.$bvModal.show('preview')
    },
    getComprovantes(id) {
      this.comprovantes = []
      axios
        .get(`api/comprovante/listar/${id}`)
        .then(res => {
          this.comprovantes = res.data.dados
        })
    },
    visualizarArquivo(nome) {
      this.srcArquivo = `${this.endpoint}/storage/${nome}`
      this.$bvModal.show('visualizar-arquivo')
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearFormAnexarComprovante() {
      this.orcamento_id = null
      this.file = null
      this.observacaoComprovante = ''
      this.aliasComprovante = ''
      this.dataPagamento = null
      this.selectedServicosOrcamentos = []
    },
    visualizarDadosBancarios(item) {
      this.orcamento_id = item.orcamento_id

      this.viewDadosBancarios = []
      if (item.prestador_servico !== null) {
        this.viewDadosBancarios.push({
          agencia: item.prestador_servico.agencia,
          banco: item.prestador_servico.banco,
          chave_pix: item.prestador_servico.chave_pix,
          conta: item.prestador_servico.conta,
          nome: item.prestador_servico.nome,
        })
      } else {
        this.viewDadosBancarios.push({
          agencia: item.agencia,
          banco: item.banco,
          chave_pix: item.chave_pix,
          conta: item.conta,
          nome: item.nome,
          nome_favorecido: item.nome_favorecido,
        })
      }
      this.viewDadosBancarios.status = item.status
      this.showDadosBancarios = true
      this.$refs.modalVisulizarDadosBancarios.show()
    },
    openModalOrcamento(item) {
      this.infoOrcamento = item
      this.visualizarDadosModal = true
      this.$refs.showModalOrcamento.show()
    },
    toViewOs(id) {
      this.$router.push({ name: 'os-view', params: { id } })
    },
    openModalRevisarOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja revisar esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$refs.modalRevisarOrcamento.show()
        }
      })
    },
    revisarOrcamento() {
      const body = {
        orcamento_id: this.orcamento_id,
        motivo: this.motivoRevisaoOrcamento.toUpperCase(),
        tipo_revisao: 'BANCÁRIA',
      }
      axios
        .post('api/orcamento/revisar', body)
        .then(() => {
          this.$refs.modalRevisarOrcamento.hide()
          this.updategrid()
          this.orcamento_id = null
          this.motivoRevisaoOrcamento = ''
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'ORÇAMENTO ENVIADO PARA REVISÃO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$refs.modalRevisarOrcamento.hide()
          this.updategrid()
          this.orcamento_id = null
          this.motivoRevisaoOrcamento = ''
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    async openModalHistorico(item) {
      await axios.get(`api/ordem_servico/historico/pagamento/${item.produto_id}`)
        .then(res => {
          this.historico = res.data.dados
        })
      this.$refs.viewHistorico.show()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
.per-page-selector {
  width: 90px;
}
.iconeThumbsUp {
  color: green;
}
</style>
