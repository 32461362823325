<template>
  <b-card>
    <l-map
      :zoom="zoom"
      :center="dataSet[0] ? [dataSet[0].latitude, dataSet[0].longitude] : []"
    >
      <l-tile-layer :url="url" />
      <l-marker
        v-for="(marker, idx) in dataSet"
        :key="idx"
        :lat-lng="[marker.latitude, marker.longitude]"
        :icon="icon"
      >
        <l-popup>{{ marker.nome }}</l-popup>
      </l-marker>
    </l-map>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet'
import { icon } from 'leaflet'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    BCard,
  },
  props: {
    dataSet: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      markerLatLng: [47.313220, -1.319482, { draggable: 'true' }],

      icon: icon({
        iconUrl: require('@/assets/images/misc/map-marker.png'),
        iconSize: [15, 25],
        iconAnchor: [16, 37],
      }),
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 700px;
  }
}
</style>

<style lang="scss">
@import '../../../@core/scss/vue/libs/map-leaflet.scss';
</style>
