<template>
  <div>
    <dash-administrador-master
      v-if="dash === 'master'"
    />
    <dash-administrador-super-master
      v-if="dash === 'superMaster'"
    />
    <dash-direcionador
      v-if="dash ==='direcionador'"
    />
    <dash-atendente
      v-if="dash ==='atendente'"
    />
    <dash-auditor
      v-if="dash ==='auditor'"
    />
    <dash-financeiro
      v-if="dash ==='financeiro'"
    />
    <dash-orcamento
      v-if="dash ==='orcamento'"
    />
    <dash-seguradora
      v-if="dash ==='seguradora'"
    />
    <dash-preventivo
      v-if="dash ==='preventivo'"
    />
  </div>
</template>
<script>
import DashAdministradorMaster from './DashAdministradorMaster.vue'
import DashAdministradorSuperMaster from './DashMaster.vue'
import DashDirecionador from '../direcionador/dashboard/dashDirecionador.vue'
import DashAuditor from '../auditor/dashboard/DashAuditor.vue'
import DashFinanceiro from '../financeiro/dashboard/DashFinanceiro.vue'
import DashAtendente from '../atendente/dashboard/DashAtendente.vue'
import DashOrcamento from '../orcamento/dashboard/DashOrcamento.vue'
import DashSeguradora from '../seguradora/dashboard/DashSeguradora.vue'
import DashPreventivo from '../preventivo/dashboard/DashPreventivo.vue'

export default {
  components: {
    DashAdministradorMaster,
    DashDirecionador,
    DashAtendente,
    DashAuditor,
    DashFinanceiro,
    DashOrcamento,
    DashSeguradora,
    DashPreventivo,
    DashAdministradorSuperMaster,
  },
  data() {
    return {
      dash: '',
    }
  },
  async created() {
    await this.selectDash()
  },
  methods: {
    selectDash() {
      const { perfil } = JSON.parse(localStorage.getItem('userData'))
      if (perfil === 'ADMINISTRADOR MASTER') {
        this.dash = 'superMaster'
      }
      if (perfil === 'ADMINISTRADOR') {
        this.dash = 'master'
      }
      if (perfil === 'DIRECIONADOR') {
        this.dash = 'direcionador'
      }
      if (perfil === 'ATENDENTE') {
        this.dash = 'atendente'
      }
      if (perfil === 'AUDITOR') {
        this.dash = 'auditor'
      }
      if (perfil === 'FINANCEIRO') {
        this.dash = 'financeiro'
      }
      if (perfil === 'ORCAMENTISTA') {
        this.dash = 'orcamento'
      }
      if (perfil === 'SEGURADORA') {
        this.dash = 'seguradora'
      }
      if (perfil === 'PREVENTIVO') {
        this.dash = 'preventivo'
      }
    },
  },
}
</script>

<route lang="yaml">
meta:
  action: read
  resource: dashboard
</route>
