<template>
  <section id="card-actions">
    <b-row>
      <!-- card filtros  -->
      <b-col md="3">
        <card-filtros />
      </b-col>

      <!-- card statistic -->
      <b-col md="9">
        <card-statistics :data="statisticsItems" />
        <b-row>
          <b-col md="12">
            <CardMap
              v-if="items"
              :data-set="mapDataset"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapState, mapGetters } from 'vuex'
import CardMap from '@/views/dashboard/cards/CardMap.vue'
import CardFiltros from './cards/CardFiltros.vue'
import CardStatistics from './cards/CardStatistics.vue'
// import axios from '../../../../axios-auth'

export default {
  components: {
    CardFiltros,
    CardStatistics,
    BRow,
    BCol,
    CardMap,
  },
  data() {
    return {
      dash: '',
      empresaID: '',
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: 'R$ 0,00',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: 'R$ 0,00',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: '0 Dia',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'RefreshCcwIcon',
          color: 'light-success',
          title: '0%',
          subtitle: 'PT',
          customClass: '',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '0%',
          subtitle: 'REC',
          customClass: '',
        },
        {
          icon: 'BoxIcon',
          color: 'light-warning',
          title: '0%',
          subtitle: 'LMI',
          customClass: '',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      items: state => state.seguradora.items,
    }),
    ...mapGetters({
      mapDataset: 'seguradora/mapDataset',
      indicadores: 'seguradora/indicadores',
    }),
  },
  watch: {
    indicadores(data) {
      const { CMR, CMG, TAT, PT, REC, LMI } = data

      CMR ? this.statisticsItems[0].title = CMR.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null
      CMG ? this.statisticsItems[1].title = CMG.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null
      CMG ? this.statisticsItems[2].title = `${TAT.toFixed(0)} Dia(s)` : null
      CMG ? this.statisticsItems[3].title = `${PT.toFixed(2)}%` : null
      CMG ? this.statisticsItems[4].title = `${REC.toFixed(2)}%` : null
      CMG ? this.statisticsItems[5].title = `${LMI.toFixed(2)}%` : null
    },
  },
  async created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    await this.$store.dispatch('seguradora/getData')
    this.$store.dispatch('seguradora/getFiltroOptions')
  },
  methods: {
  },
}
</script>
