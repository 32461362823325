<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      title="Ordem de Serviço"
      class="mb-0"
    >
      <!-- Table Top -->
      <b-row>
        <b-col
          md="2"
          class="mb-2"
        >
          <label>Status Agendamento</label>
          <v-select
            v-model="statusAgendamentoFilter"
            :options="optionsStatusAgendamentoFilter"
            multiple
          />
        </b-col>
        <div class="col-md-2 mb-2">
          <label>Status OS</label>
          <v-select
            v-model="statusFilter"
            :options="optionsStatusFilter"
            multiple
          />
        </div>
        <div class="col-md-2 mb-2">
          <label>Quant. de Dias em Aberto</label>
          <v-select
            v-model="QDAFilter"
            :options="optionsQDAFilter"
            multiple
          />
        </div>
        <div class="col-md-2 mb-2">
          <label>Seguradora</label>
          <v-select
            v-model="seguradoraFilter"
            :options="optionsSeguradoraFilter"
            multiple
          />
        </div>
        <div class="col-md-2 mb-2">
          <label>Filtro por Revendas</label>
          <v-select
            v-model="revendaFilter"
            :options="optionsRevendaFilter"
            multiple
          />
        </div>
        <div class="col-md-2 mb-2">
          <label>Filtro por Linha</label>
          <v-select
            v-model="linhaFilter"
            :options="optionsLinhaFilter"
            multiple
          />
        </div>
        <div class="col-md-2 mb-2">
          <label>Filtro por Reclamação</label>
          <v-select
            v-model="selectedReclamacaoFilter"
            :options="optionsReclamacaoFilter"
          />
        </div>
        <div class="col-md-2 mb-2">
          <label>Técnico</label>
          <v-select
            v-model="selectedTecnicoFilter"
            :options="optionsTecnicoFilter"
            multiple
          />
        </div>
        <div class="col-md-1 mb-1">
          <label>Visita</label>
          <v-select
            v-model="visitaFilter"
            :options="optionsVisitaFilter"
            multiple
          />
        </div>
        <div class="col-md-1 mb-1">
          <label>Reparo</label>
          <v-select
            v-model="reparoFilter"
            :options="optionsReparoFilter"
            multiple
          />
        </div>
        <b-button
          style="width: 150px; height: 45px; margin-top: 15px"
          variant="primary"
          @click="showModalFiltro = true, load = true"
        >
          Mais Filtros
        </b-button>
      </b-row>
      <div class="m-2">

        <!-- Table Top -->
        <b-row
          class="mb-2"
          align-h="between"
        >
          <!-- Search -->
          <b-col cols="1">
            <b-form-select
              v-model="perPage"
              label="text"
              :options="[10, 25, 50, 100]"
              size="sm"
            />
          </b-col>
          <b-col cols="1">
            <b-badge
              class="p-1"
              pill
              variant="light-primary"
            >
              Total: {{ totalRows }}
            </b-badge>

          </b-col>
          <b-col
            lg="9"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
            />
          </b-col>

        </b-row>

      </div>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-row>
          <b-col md="12">
            <b-table
              ref="refUserListTable"
              hover
              class="position-relative"
              :current-page="currentPage"
              :filter="searchQuery"
              :items="listFiltered"
              :per-page="perPage"
              responsive
              :fields="fields"
              show-empty
              empty-text="Sem dados!"
              :tbody-tr-class="setRowClassStyle"
              sticky-column
              @filtered="onFiltered"
              @row-clicked="onRowClicked"
            >
              <template #cell(id)="data">
                <a :href="'/os/view/' + data.item.id">{{ data.item.id }}</a>
              </template>
              <!-- Column: Actions -->
              <template #cell(notificacao)="data">

                <b-row class="justify-content-between align-items-center flex-nowrap">

                  <b-col cols="auto">
                    <feather-icon
                      v-b-tooltip.hover.top="'Informações'"
                      icon="MailIcon"
                      size="21"
                      class="text-secondary"
                      :badge="data.item.total_informacao ? data.item.total_informacao : '0'"
                      badge-classes="badge-secondary badge-glow"
                      @click="irParaOS(data.item.id)"
                    />
                  </b-col>
                  <b-col cols="auto">
                    <feather-icon
                      v-b-tooltip.hover.top="'Reagendamento Reparo'"
                      icon="CalendarIcon"
                      size="21"
                      class="text-secondary"
                      :badge="data.item.total_reagendamento_reparo ? data.item.total_reagendamento_reparo : '0'"
                      badge-classes="badge-secondary badge-glow"
                    />
                  </b-col>
                  <b-col cols="auto">
                    <feather-icon
                      v-b-tooltip.hover.top="'Reagendamento Visita'"
                      icon="CalendarIcon"
                      size="21"
                      class="text-secondary"
                      :badge="data.item.total_reagendamento_visita ? data.item.total_reagendamento_visita : '0'"
                      badge-classes="badge-secondary badge-glow"
                    />
                  </b-col>
                  <b-col cols="auto">
                    <div v-if="data.item.reclamacao">
                      <feather-icon
                        v-b-tooltip.hover.top="'Reclamação'"
                        icon="AlertTriangleIcon"
                        size="21"
                        class="text-secondary"
                        :badge="data.item.reclamacao ? data.item.reclamacao : '0'"
                        badge-classes="badge-danger badge-glow"
                      />
                    </div>
                  </b-col>
                  <div
                    v-if="data.item.agendamento_status === 'Hoje' && data.item.status === 'EM ATENDIMENTO' || data.item.status === 'AGENDAR'"
                  >
                    <b-overlay
                      show
                      spinner-variant="danger"
                      spinner-type="grow"
                      spinner-small
                      rounded="sm"
                    >
                      <b-col cols="auto">
                        <feather-icon
                          icon="MailIcon"
                          size="10"
                          class="text-secondary"
                        />
                      </b-col>
                    </b-overlay>
                  </div>
                </b-row>
              </template>

              <template #cell(data_abertura)="data">
                {{ americaDate(data.item.data_abertura) }}
              </template>
              <template #cell(data_agendamento)="data">
                <div v-if="data.item.data_agendamento != null">
                  {{ americaDate(data.item.data_agendamento) }}
                </div>
              </template>
              <template #cell(previsao_reparo)="data">
                <div v-if="data.item.previsao_reparo != null">
                  {{ americaDate(data.item.previsao_reparo) }}
                </div>
              </template>
              <template #cell(status)="data">
                <b-badge :variant="badgeVariantPerfil(data.item.status)">
                  {{ data.item.status }}
                </b-badge>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modalTransferirAtendente"
      ok-title="Transferir"
      cancel-title="Cancelar"
      cancel-variant="danger"
      title="Transferir Atendimento"
      no-close-on-backdropgti
      :ok-disabled="descricaoTransferenciaAtendente === ''"
      @ok="transferirAtendente"
    >
      <div>
        <label>Selecione o Atendente</label>
        <v-select
          v-model="selectedAtendente"
          :options="optionsAtendente"
        />
        <label>Descrição: </label>
        <b-form-textarea v-model="descricaoTransferenciaAtendente" />
      </div>
    </b-modal>
    <modal-fitro
      :show-modal="showModalFiltro"
      @modal-hidden="onModalHidden"
      @responsefiltro="responseFiltro"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BBadge,
  BButton,
  BFormTextarea, BFormSelect, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import modalFitro from '../../../componentesglobais/ModalFitro.vue'

import axios from '../../../../../axios-auth'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BBadge,
    BFormSelect,
    vSelect,
    BButton,
    modalFitro,
    BFormTextarea,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    // eslint-disable-next-line
  },
  data() {
    return {
      itemsReport: [],
      fields: [
        { key: 'id', label: 'id', class: 'text-center', sortable: 'true' },
        { key: 'notificacao', label: 'Notificação', class: 'text-center', sortable: 'true' },
        { key: 'data_abertura', label: 'Abertura', class: 'text-center', sortable: 'true' },
        { key: 'dias_em_aberto', label: 'QDA', class: 'text-center', sortable: 'true' },
        { key: 'data_agendamento', label: 'Data Agendamento', class: 'text-center', sortable: 'true' },
        { key: 'previsao_reparo', label: 'Previsao de Reparo', class: 'text-center', sortable: 'true' },
        { key: 'agendamento_status', label: 'Status Agendamento', class: 'text-center', sortable: 'true' },
        { key: 'sinistro', label: 'sinistro', class: 'text-center', sortable: 'true' },
        { key: 'status', label: 'status', class: 'text-center', sortable: 'true' },
        { key: 'tipo_atendimento', label: 'tipo atendimento', class: 'text-center', sortable: 'true' },
        { key: 'cliente', label: 'cliente', class: 'text-center', sortable: 'true' },
        { key: 'produto', label: 'produto', class: 'text-center', sortable: 'true' },
        { key: 'seguradora', label: 'seguradora', class: 'text-center', sortable: 'true' },
        { key: 'revenda', label: 'revenda', class: 'text-center', sortable: 'true' },
        { key: 'linha', label: 'linha', class: 'text-center', sortable: 'true' },
        { key: 'marca', label: 'marca', class: 'text-center', sortable: 'true' },
        { key: 'tecnico', label: 'tecnico', class: 'text-center', sortable: 'true' },
        { key: 'cidade_nome', label: 'Cidade', class: 'text-center', sortable: 'true' },
        { key: 'estado_uf', label: 'Estado', class: 'text-center', sortable: 'true' },
      ],
      sortBy: 'dias_em_aberto',
      perPage: 100,
      currentPage: 1,
      totalRows: 1,
      searchQuery: null,
      osId: null,
      optionsAtendente: [],
      selectedAtendente: [],
      selectedTecnicoFilter: [],
      selectedReclamacaoFilter: [],
      showModalFiltro: false,
      descricaoTransferenciaAtendente: '',
      motivoEventoOptions: [],
      motivoEventoID: 2,
      empresaID: null,
      atendenteFilter: [],
      statusFilter: [],
      statusAgendamentoFilter: [],
      revendaFilter: [],
      seguradoraFilter: [],
      linhaFilter: [],
      tipoAtendimentoFilter: [],
      QDAFilter: [],
      reclamacaoFilter: [],
      visitaFilter: [],
      reparoFilter: [],
      optionsQDAFilter: ['Mais de 5 dias', 'Mais de 10 dias', 'Mais de 15 dias', 'Mais de 30 dias'],
      load: true,
    }
  },
  computed: {
    ...mapState({
      items: state => state.direcionador.items,
      loadingData: state => state.direcionador.loadingData,
      statusClick: state => state.direcionador.statusClick,
    }),
    optionsAtendenteFilter() {
      const atendente = []
      this.items.map(item => {
        item.atendente ? atendente.push(item.atendente) : null
      })
      const itemsFiltered = [...new Set(atendente)]
      return itemsFiltered
    },
    optionsRevendaFilter() {
      const revenda = []
      this.items.map(item => {
        item.revenda ? revenda.push(item.revenda) : null
      })
      const itemsFiltered = [...new Set(revenda)]
      return itemsFiltered
    },
    optionsTecnicoFilter() {
      const tecnico = []
      this.items.map(item => {
        item.status_tecnico ? tecnico.push(item.status_tecnico) : null
      })
      const itemsFiltered = [...new Set(tecnico)]
      return itemsFiltered
    },
    optionsSeguradoraFilter() {
      const seguradora = []
      this.items.map(item => {
        item.seguradora ? seguradora.push(item.seguradora) : null
      })
      const itemsFiltered = [...new Set(seguradora)]
      return itemsFiltered
    },
    optionsStatusFilter() {
      this.status = ''
      const status = []
      this.items.map(item => {
        item.status ? status.push(item.status) : null
      })
      const itemsFiltered = [...new Set(status)]
      return itemsFiltered
    },
    optionsStatusAgendamentoFilter() {
      this.status = ''
      const status = []
      this.items.map(item => {
        item.status ? status.push(item.agendamento_status) : null
      })
      const itemsFiltered = [...new Set(status)]
      return itemsFiltered
    },
    optionsLinhaFilter() {
      const linha = []
      this.items.map(item => {
        item.linha ? linha.push(item.linha) : null
      })
      const itemsFiltered = [...new Set(linha)]
      return itemsFiltered
    },
    optionsTipoAtendimentoFilter() {
      const tipoAtendimento = []
      this.items.map(item => {
        item.tipo_atendimento ? tipoAtendimento.push(item.tipo_atendimento) : null
      })
      const itemsFiltered = [...new Set(tipoAtendimento)]
      return itemsFiltered
    },
    optionsReclamacaoFilter() {
      const reclamacao = ['COM RECLAMACÃO', 'SEM RECLAMAÇÃO']
      return reclamacao
    },
    optionsReparoFilter() {
      const reparo = []
      this.items.map(item => {
        item.total_reagendamento_reparo ? reparo.push(item.total_reagendamento_reparo) : null
      })
      const itemsFiltered = [...new Set(reparo)]
      return itemsFiltered
    },
    optionsVisitaFilter() {
      const visita = []
      this.items.map(item => {
        item.total_reagendamento_visita ? visita.push(item.total_reagendamento_visita) : null
      })
      const itemsFiltered = [...new Set(visita)]
      return itemsFiltered
    },
    listFiltered() {
      if (this.statusClick) {
        this.statusFilter = []
        this.statusFilter.push(this.statusClick)
        this.$store.commit('direcionador/SET_STATUS_CLICK', null)
      }
      const { items, revendaFilter: rF, linhaFilter: lF, QDAFilter: dF, seguradoraFilter: xF, atendenteFilter: aF, statusFilter: sF, selectedReclamacaoFilter: sRF, statusAgendamentoFilter: sAF, reparoFilter: qRF, visitaFilter: qVF, selectedTecnicoFilter: tcF } = this
      let list = items

      // Filtro por LInha
      if (lF.length > 0) {
        list = list.filter(item => lF.includes(item.linha))
      }
      // Filtro por Status Agendamento
      if (sAF.length > 0) {
        list = list.filter(item => sAF.includes(item.agendamento_status))
      }
      // Filtro por Status
      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status))
      }
      // Filtro por Atentende
      if (aF.length > 0) {
        list = list.filter(item => aF.includes(item.atendente))
      }
      // Filtro por status de tecnico
      if (tcF.length > 0) {
        list = list.filter(item => tcF.includes(item.status_tecnico))
      }
      // Filtro por Revenda
      if (rF.length > 0) {
        list = list.filter(item => rF.includes(item.revenda))
      }
      // Filtro por Seguradora
      if (xF.length > 0) {
        list = list.filter(item => xF.includes(item.seguradora))
      }
      // Filtro por dias em aberto
      if (dF.length > 0) {
        const qt = this.QDAFilter.length - 1
        const diasFiltro = parseInt(this.QDAFilter[qt].replace(/[^0-9]/g, ''))
        list = list.filter(item => this.diasVencidoCalc(item.data_abertura) >= diasFiltro)
      }
      // Filtro por Reclamação
      if (sRF && sRF.length > 0) {
        if (sRF === 'COM RECLAMACÃO') {
          list = list.filter(item => item.reclamacao > 0)
        } else {
          list = list.filter(item => item.reclamacao === 0)
        }
      }
      // Filtro por numero de reagendamento de visita
      if (qVF.length > 0) {
        list = list.filter(item => qVF.includes(item.total_reagendamento_visita))
      }
      // Filtro por numero de reagendamento de reparo
      if (qRF.length > 0) {
        list = list.filter(item => qRF.includes(item.total_reagendamento_reparo))
      }
      this.totalRows = list.length

      this.$store.commit('direcionador/SET_FILTERED_LIST', list)

      return list
    },
  },
  created() {
    const userId = localStorage.getItem('userId')
    this.getListOs(userId)
  },
  methods: {
    onRowClicked(item) {
      this.$router.push({ name: 'os-view', params: { id: item.id } })
    },
    irParaOS(id) {
      this.$router.push({ name: 'os-view', params: { id } })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getListOs(empresaID) {
      this.items = []
      axios.get(`api/ordem_servico/listar/atendente/${empresaID}`)
        .then(res => {
          this.load = false
          res.data.dados.map(item => {
            item.agendamento_status = this.verificarData(item.data_agendamento, item.previsao_reparo)
            this.items.push(item)
          })
          this.totalRows = this.items.length
        })
    },
    verificarData(data, previsao) {
      if (previsao) {
        data = previsao
      }
      if (!data) {
        return 'Não Agendado'
      }
      const dataAtual = new Date()
      let dia = dataAtual.getDate()
      let mes = dataAtual.getMonth() + 1
      const ano = dataAtual.getFullYear()

      if (mes < 10) {
        mes = `0${mes}`
      }
      if (dia < 10) {
        dia = `0${dia}`
      }
      let d1 = `${ano}-${mes}-${dia}`
      d1 = d1.replace(/\D/g, '')
      const d2 = data.replace(/\D/g, '')

      parseInt(d1)
      parseInt(d2)

      if (!previsao && d2 < d1) {
        return 'Desatualizado'
      } if (d1 === d2) {
        return 'Hoje'
      } if (d2 > d1) {
        return 'Próximos dias'
      }
      return 'Realizado'
    },
    async openModalTransferirAtendente(id) {
      this.osId = id
      await this.getAtendentes(this.empresaID)
      await this.getMotivoEventos()
      this.$refs.modalTransferirAtendente.show()
    },
    diasVencidoCalc(data) {
      const dataAtual = new Date()
      const dataAbertura = new Date(data)
      const diferencaEmDias = Math.floor((dataAtual - dataAbertura) / (1000 * 60 * 60 * 24))
      return diferencaEmDias
    },
    getAtendentes(empresaId) {
      this.optionsAtendente = []
      axios.get(`api/empresas/atendentes/${empresaId}`)
        .then(response => {
          response.data.dados.map(item => {
            this.optionsAtendente.push({
              label: item.name,
              id: item.id,
            })
          })
        })
    },
    getMotivoEventos() {
      this.motivoEventoOptions = []
      axios
        .get('api/motivo_evento/')
        .then(res => {
          res.data.dados.map(item => {
            this.motivoEventoOptions.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    badgeVariantPerfil(perfil) {
      const colors = {
        AGENDAR: 'light-warning',
        'EM ATENDIMENTO': 'light-primary',
        'AGUARDANDO APROVAÇÃO': 'light-info',
        'EM REPARO': 'light-secondary',
        'REPARO CONCLUÍDO': 'light-primary',
        'REPARO ENTREGUE': 'light-success',
        CANCELADO: 'light-danger',
      }
      return colors[perfil] || 'light-primary'
    },
    transferirAtendente() {
      const body = {
        atendente_id: this.selectedAtendente.id,
        motivo_evento_id: this.motivoEventoID,
        descricao: this.descricaoTransferenciaAtendente,
      }

      axios.put(`api/ordem_servico/atualizar/atendente/${this.osId}`, body)
        .then(() => {
          this.clearTransferirAtendente()
          this.getListOs(this.empresaID)
          this.$swal({
            title: 'Atendente transferido!',
            icon: 'success',
            text: 'Atendente transferido!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.clearTransferirAtendente()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    clearTransferirAtendente() {
      this.selectedAtendente = []
      this.osId = null
      this.descricaoTransferenciaAtendente = ''
    },
    onModalHidden() {
      this.showModalFiltro = false
    },
    responseFiltro(item) {
      this.load = false
      this.items = item
      this.totalRows = item.length
    },

    setRowClassStyle(item, type) {
      if (!item || type !== 'row') return null
      if (item.status === 'Desatualizado') return 'table-danger text-danger'

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
