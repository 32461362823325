<template>
  <div>
    <div>
      <b-card>
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <b-row>
            <b-col cols="12">
              <b-row align-h="between">
                <b-col
                  md="12"
                  sm="12"
                  class="my-1"
                >
                  <h3 class="text-center">
                    Estados
                  </h3>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-direction="sortDirection"
                :filter-included-fields="filterOn"
              >
                <template #cell(nome_fantasia)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :text="getInitials(data.item.nome_fantasia)"
                        :style="{ backgroundColor: randomColor(data.item.id) }"
                        :to="{
                          name: 'empresa-view',
                          params: { id: data.item.id },
                        }"
                      />
                    </template>
                    <b-link
                      :to="{
                        name: 'empresa-view',
                        params: { id: data.item.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ data.item.nome_fantasia }}
                    </b-link>
                  </b-media>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <p
                align="center"
              >
                <span
                  class="cursor-pointer"
                  @click="carregarMais()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  Carregar Mais
                </span>
                <span
                  v-show="limite > 10"
                  class="cursor-pointer ml-2"
                  @click="carregarMenos()"
                >
                  <feather-icon
                    icon="MinusIcon"
                    class="mr-50"
                  />
                  Carregar Menos
                </span>
              </p>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BMedia,
  BLink,
  BRow,
  BCol,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    BTable,
    BAvatar,
    BMedia,
    BLink,
    BRow,
    BCol,
    BCard,
    BOverlay,
  },
  data() {
    return {
      limite: 10,
      load: true,
      perPage: 500,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      fields: [
        { key: 'posicao', label: '#' },
        {
          key: 'estado_nome',
        },
        { key: 'quantidade', label: 'Quantidade' },
      ],
    }
  },
  async created() {
    await this.updateList()
  },
  methods: {
    async updateList() {
      this.load = true
      await axios
        .get(`api/empresas/totalizadores/estado/${this.limite}`)
        .then(res => {
          const dados = []
          let count = 0
          res.data.dados.map(i => {
            count += 1
            i.posicao = count
            dados.push(i)
          })
          this.items = dados
          this.load = false
        })
    },
    carregarMais() {
      this.limite += 5
      this.load = true
      this.updateList()
    },
    carregarMenos() {
      this.limite -= 5
      this.load = true
      this.updateList()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.is-invalid {
  border-color: red !important;
}
</style>
