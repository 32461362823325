<template>
  <div>
    <b-row>
      <b-col md="12">
        <AnalyticHeader />
      </b-col>
      <b-col md="12">
        <compromissos-atendente />
      </b-col>
    </b-row></div>
</template>

<script>
import AnalyticHeader from '@/components/indicadores/header.vue'
import { BCol, BRow } from 'bootstrap-vue'
// import statisticsDirecionador from '../../dashboard/cards/CardStatistics.vue'
// import supportAnalitcs from './cards/CardAnalytcsSupport.vue'
import compromissosAtendente from './cards/CardCompromissosAtendente.vue'
// import totaisAtendimentoStatus from './cards/TotaisAtendimentoStatus.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticHeader,
    compromissosAtendente,
  },
  data() {
  },
  async mounted() {
    const { userId } = localStorage
    this.$store.dispatch('direcionador/getTotalizadoresOs', { atId: userId })
  },
}
</script>
