<template>
  <b-card class="card-congratulation-medal">
    <h5>Filtros</h5>
    <label>Data Inicio</label>
    <b-form-input
      id="abertura-inicio"
      :value="aberturaModel.dataInicial"
      locale="pt"
      type="date"
      @input="setAbertura('dataInicial', $event)"
    />
    <label>Data Final</label>
    <b-form-input
      id="abertura-final"
      :value="aberturaModel.dataFinal"
      locale="pt"
      type="date"
      @change="setAbertura('dataFinal', $event)"
    />
    <label>Revenda</label>
    <v-select
      :options="revendaModel.options"
      multiple
      @input="updateFiltroModel('revenda', $event)"
    />
    <label>Marca</label>
    <v-select
      :options="marcaModel.options"
      multiple
      @input="updateFiltroModel('marca', $event)"
    />
    <label>Linha</label>
    <v-select
      :options="linhaModel.options"
      multiple
      @input="updateFiltroModel('linha', $event)"
    />
    <label>Tipo Atendimento</label>
    <v-select
      :options="tipoAtendimentoModel.options"
      multiple
      @input="updateFiltroModel('tipoAtendimento', $event)"
    />
    <label>Status Atendimento</label>
    <v-select
      :options="statusAtendimentoModel.options"
      multiple
      @input="updateFiltroModel('statusAtendimento', $event)"
    />
    <label>Região</label>
    <v-select
      :options="regiaoModel.options"
      multiple
      @input="updateFiltroModel('regiao', $event)"
    />
    <label>Estado</label>
    <v-select
      :options="estadoModel.options"
      multiple
      @input="updateFiltroModel('estado', $event)"
    />
    <label>Cidade</label>
    <v-select
      :options="cidadeModel.options"
      multiple
      @input="updateFiltroModel('cidade', $event)"
    />
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mt-1"
      variant="primary"
      @click="$store.dispatch('seguradora/setFiltro')"
    >
      Filtrar
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      abertura: {
        inicio: '',
        final: '',
      },
    }
  },
  computed: {
    ...mapState({
      revendaModel: state => state.seguradora.revenda,
      linhaModel: state => state.seguradora.linha,
      marcaModel: state => state.seguradora.marca,
      tipoAtendimentoModel: state => state.seguradora.tipoAtendimento,
      statusAtendimentoModel: state => state.seguradora.statusAtendimento,
      regiaoModel: state => state.seguradora.regiao,
      estadoModel: state => state.seguradora.estado,
      cidadeModel: state => state.seguradora.cidade,
      aberturaModel: state => state.seguradora.abertura,
    }),
  },
  methods: {
    setAbertura(stage, value) {
      this.$store.commit('seguradora/SET_ABERTURA_FILTRO', { stage, value })
    },
    updateFiltroModel(state, data) {
      this.$store.commit('seguradora/SET_FILTRO_STATE', { name: state, data })
      if (state === 'estado') {
        this.$store.dispatch('seguradora/cidadeEstado')
      }
    },
  },
}

</script>
