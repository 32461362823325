<template>
  <section id="card-actions">
    <b-row>
      <!-- card filtros  -->
      <b-col md="3">
        <card-filtros @map-json="capturarEvento" />
      </b-col>

      <!-- card statistic -->
      <b-col md="9">
        <card-statistics :data="statisticsItems" />
        <b-row>
          <b-col md="12">
            <CardMap
              v-if="dados"
              :data-set="dados.dados.mapDataset"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import 'leaflet/dist/leaflet.css'
import CardFiltros from './cards/CardFiltros.vue'
import CardStatistics from './cards/CardStatistics.vue'
import CardMap from './cards/CardMap.vue'

export default {
  components: {
    CardFiltros,
    CardStatistics,
    BRow,
    BCol,
    CardMap,
  },
  data() {
    return {
      dash: '',
      empresaID: '',
      arquivoJson: '040416202305096459f070df4ae.json',
      dados: null,
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: 'R$ ',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: 'R$ ',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: 'Dias',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-warning',
          title: '%',
          subtitle: 'PT',
          customClass: '',
        },
        {
          icon: 'BoxIcon',
          color: 'light-success',
          title: '%',
          subtitle: 'REP',
          customClass: '',
        },
        {
          icon: 'RepeatIcon',
          color: 'light-info',
          title: '%',
          subtitle: 'REIN',
          customClass: '',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '%',
          subtitle: 'REC',
          customClass: '',
        },
        {
          icon: 'BoxIcon',
          color: 'light-warning',
          title: '%',
          subtitle: 'LMI',
          customClass: '',
        },
      ],
      codePopup: '',

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [-7.215303, -39.410299],
      markerLatLng: [47.313220, -1.319482, { draggable: 'true' }],
    }
  },
  async created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    await this.selectDash()
  },
  methods: {
    capturarEvento(dados) {
      this.dados = null
      this.arquivoJson = null
      this.arquivoJson = dados.dados.json
      this.dados = dados
      this.statisticsItems[0].title = `R$ ${dados.dados.CMR.toFixed(2)}`
      this.statisticsItems[1].title = `R$ ${dados.dados.CMG.toFixed(2)}`
      this.statisticsItems[2].title = `${dados.dados.TAT.toFixed(0)} Dias`
      this.statisticsItems[3].title = `${dados.dados.PT}%`
      this.statisticsItems[4].title = `${dados.dados.REP}%`
      this.statisticsItems[5].title = `${dados.dados.REIN}%`
      this.statisticsItems[6].title = `${dados.dados.REC.toFixed(2)}%`
      this.statisticsItems[7].title = `${dados.dados.LMI.toFixed(2)}%`
    },
  },
}
</script>
